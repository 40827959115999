<template>
<v-row class="fill-height">
    <v-col>
        <v-sheet height="64">
            <v-toolbar flat>
                <v-btn fab text small color="grey darken-2" @click="$refs.calendar.prev()">
                    <v-icon small>
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="$refs.calendar.next()">
                    <v-icon small>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-col lg="3" md="3" cols="6" class="s-col-form">
                    <c-select-area full clearable noDefault v-model="AreID" label="Área" v-if="$fun.isAdmin()" :typeArea="3" />
                </v-col>
            </v-toolbar>

        </v-sheet>
        <v-sheet height="auto">
            <v-calendar locale="es" ref="calendar" v-model="value" color="primary" @click:event="showEvent" :start="start" :end="end" @moved="moved">
                <template #day="{ past, date}">
                    <v-card style="padding-bottom:12px;" v-for="d in requiremennDay(date)" elevation="0" :key="d.OrdDocumentNumber">

                        <div v-for="m in d.items" :key="m.OrdDocumentNumber">
                            <!--  -->
                            <v-hover v-slot="{ hover }" style="margin-top:20px">
                                <v-card dense outlined :class="{ 'pt-1 pb-1 pr-2 pl-2 mb-2 on-hover': hover }" style="border-radius: 10px;">

                                    <v-card-subtitle class="" style="position: relative;padding-bottom:0px !important;padding-top:5px !important;font-size:12px !important;">
                                        <!-- <b><a v-if="m.OrdAttach1 !== null && m.OrdAttach1.length > 0" @click="openFile({name: m.OrdAttach1})">{{m.OrdDocumentNumber}}</a></b>
                                        <b><a v-if="m.OrdAttach1 == null || m.OrdAttach1.length == 0">{{m.OrdDocumentNumber}}</a></b> -->
                                        <b><a>{{m.OrdDocumentNumber}} - {{m.typeBusinessName}}</a></b>
                                    </v-card-subtitle>

                                    <!-- <v-expand-transition>
                                        <v-col cols="12" v-if="hover" class="d-flex  v-card--reveal black--text" style="margin-bottom:-30px">
                                            <v-row justify="center">
                                                <v-col cols="12" style="font-size:12px;font-family:Calibri">
                                                    {{m.SupName}}
                                                    <br><b>Solicitado por:</b> <br>{{m.NtpFullName}}
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-expand-transition> -->

                                    <v-card-text class="position: relative; mt-2">
                                        <!-- <br><b style="font-size: 10px">{{m.SupName}}</b> -->
                                        <b>{{m.SupName}}</b>
                                        <br><b>Solicitado por:</b> <br>{{m.NtpFullName}}
                                        <br><b>{{m.TypeCurrencyName}} {{m.OrdTotal.toLocaleString("es-MX")}}</b>
                                        <br><b>Area:</b> {{m.AreName}}
                                        <!-- <br><b>Prioridad:</b>
                                        <v-chip x-small :color="m.TypePriorityColor">
                                            {{ m.TypePriorityName }}
                                        </v-chip> -->
                                        <br>
                                        <template>

                                            <v-icon :color="
                                                    m.OrdStatus == 1 ? 'green' : 
                                                    m.OrdStatus == 2 ? 'brown' : 
                                                    m.OrdStatus == 3 ? 'red' : 
                                                    m.OrdStatus == 4 ? 'blue' : 
                                                    m.OrdStatus == 5 ? 'brown' : 
                                                    m.OrdStatus == 6 ? 'orange' : 
                                                    m.OrdStatus == 8 ? 'red' : '' 
                                                " style="font-size:16px;">
                                                {{
                                                m.OrdStatus == 1 ? 'far fa-thumbs-up' : 
                                                m.OrdStatus == 2 ? 'fas fa-truck' : 
                                                m.OrdStatus == 3 ? 'far fa-thumbs-down' : 
                                                m.OrdStatus == 4 ? 'fa-regular fa-clock' : 
                                                m.OrdStatus == 5 ? 'fas fa-lock' : 
                                                m.OrdStatus == 6 ? 'fas fa-ban' :
                                                m.OrdStatus == 8 ? 'fas fa-hands' : '' 

                                            }}

                                            </v-icon>
                                            <a style="margin-left: 6px">{{m.OrdStatusName}}</a>
                                            <v-btn title="Visualizar" text fab x-small @click="openDialog(m)" elevation="0"><i class="fas fa-eye"></i></v-btn>
                                        </template>
                                        <br>
                                        <!-- <template>
                                            Cotizado: {{m.CttDocumentNumber}}
                                            <v-btn v-if="m.CttDocumentNumber !== null" style="margin-left:10px" icon x-small elevation="0" @click="viewCotizationDialog =true"><i style="font-size: 16px; color:green" class="fas fa-eye"></i></v-btn>
                                        </template> -->

                                        <!-- <v-btn v-if="m.OrdStatus == 1" fab x-small @click="pdf(m)" elevation="0"><i style="color:red" class="fas fa-file-pdf"></i></v-btn> -->
                                    </v-card-text>

                                </v-card>
                            </v-hover>
                        </div>
                    </v-card>
                </template>
            </v-calendar>
        </v-sheet>
    </v-col>
    <v-dialog v-if="dialogEdit" v-model="dialogEdit" persistent width="100%">
        <v-card>
            <order-purchase-edit @onClose="dialogEdit = false; $refs.crud.refresh();" :value="parentID" :typeOrder="1" :order="{}" :viewLoad="true" />
        </v-card>
    </v-dialog>

    <!-- <v-dialog v-if="processing" v-model="processing" width="400">
        <v-card color="primary" dark>
            <v-card-text>
                Por favor espere ...
                <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
            </v-card-text>

        </v-card>
    </v-dialog> -->
    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

</v-row>
</template>

<script>
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";
import _sGeneral from "@/services/HelperService.js";

export default {
    components: {
        ApprovalLevelsDocument,
        OrderPurchaseEdit
    },
    data: () => ({

        processing: false,
        messageProcessing: "",

        dates: [],
        week: 0,
        value: null,
        title: "",
        dialog: false,
        events: [],
        start: null,
        end: null,
        typeOrder: 1,
        parentID: 0,
        dialogApproval: false,
        dialogEdit: false,
        AreID: null
    }),

    created() {
        if (!this.$fun.isAdmin()) this.AreID = this.$fun.getUserInfo().CtrContract.AreID;
        this.calendar(this.$moment(new Date()));
    },

    watch: {
        AreID() {
            this.calendar(this.$moment(new Date()));
        }
    },

    methods: {

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 1).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        openDialog(item) {
            this.parentID = item.OrdID
            this.dialogEdit = true
        },

        pdf(item) {
            this.processing = true
            _sOrderPurchase.PDF(item, this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, "Orden_compra");
                        this.processing = false
                    }

                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        },

        moved({
            date
        }) {
            this.calendar(date);
        },

        requiremennDay(date) {
            return this.dates.filter((x) => {
                return x.start === date;
            });
        },

        calendar(date) {

            this.dates = [];
            this.title = this.$moment(date)
                .format("MMMM")
                .toUpperCase();
            this.start = this.$moment(date).format("YYYY-MM-01");
            let days = this.$moment(this.start).daysInMonth();
            this.end = this.$moment(this.start)
                .add(days, "d")
                .format("YYYY-MM-DD");

            let obj = {
                searchText: "",
                dir: "",
                asc: "",
                length: -1,
                filter: {
                    TypeOrder: 0, //this.typeOrder,
                    BeginDate: this.start,
                    EndDate: this.end,
                    OrdStatus: null,
                    SupCode: null,
                    UsrCreateID: null,
                    AreID: this.AreID
                },
            };

            _sOrderPurchase.pagination(obj, this.$fun.getUserID()).then((r) => {
                var daysInMonth = this.$moment().daysInMonth() + 6;
                let i = 0;
                while (i <= daysInMonth) {
                    i++;
                    var current = this.$moment(this.start).date(i).format("YYYY-MM-DD");

                    let date = {
                        start: current,
                        items: r.data.data.filter(
                            (x) => this.$moment(x.OrdDate).format("YYYY-MM-DD") == current
                        ),
                    };
                    this.dates.push(date);
                }

            })

        },

        showEvent() {
            return this.dates;
        },
    },
}
</script>

<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    left: 0;
    justify-content: center;
    opacity: 1;
    width: 100%;
    z-index: 99999;
}
</style>
